<template>
  <div v-if="departures[id] != null" class="mobilityNearbyList shadow-5">
    <div class="topBar">
      <m-icons-type
        :type="station.type"
        rounded
      />
      <div class="text-h6 text-capitalize">
        {{ station.name }}
      </div>
    </div>
    <q-scroll-area v-if="!loading && departures[id]">
      <span class="bigger">{{ $t('train.live_departures') }}:</span>
      <m-timetable-card
        v-for="item in departures[id]"
        :key="`${item.id}`"
        :item="item"
        transition="jump-up"
        once
        class="departure"
      />
    </q-scroll-area>
    <div v-else-if="loading" class="loadingContainer">
      <component :is="spinnerName" class="spinner" size="64px" />
    </div>
    <m-empty-state v-else size="64px" :font-size="24" :label="$t('nothing_found_nearby')" icon="far fa-frown" />
    <button class="done-btn" @click="$emit('close-dialog')">
      {{ $t('done') }}
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import { MTimetableCard, MIconsType, MSpinnerGeo, MSpinnerArrow, MSpinnerKinto, MEmptyState } from 'components/'

export default {
  components: {
    MTimetableCard,
    MIconsType,
    MSpinnerGeo,
    MSpinnerArrow,
    MSpinnerKinto,
    MEmptyState
  },
  props: {
    id: String,
    station: Object
  },
  computed: {
    ...mapGetters({
      departures: 'discovery/departures',
      curLocation: 'discovery/location',
      loading: 'discovery/loading'
    }),
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    }
  },
  mounted () {
    this.$store.dispatch('discovery/setTimetable', { ...this.station, limit: 20 })
  },
  methods: {
    openURL
  }
}
</script>
<style scoped lang="stylus">
  .bigger
    font-size 115%
    font-weight 450
  .q-scrollarea
    height 80%
    padding 0 20px
  .topBar
    display flex
    flex-direction row
    align-items center
    justify-content: flex-start
    height 10%
  .mobilityNearbyList
    position relative
    border-radius 10px 10px 0 0
    z-index 11
    width 800px
    height 800px
    position absolute
    background-color white
    bottom 0
    right calc(50% - 400px)
    .q-btn
      font-size .75em
      padding 2px 4px 2px 4px

  .icon-type
    margin 0 15px

  .done-btn
    position absolute
    bottom 2%
    background: var(--q-color-primary)
    color white
    border none
    border-radius 10px
    width 90%
    max-width 720px
    margin 0 5%
    height 7%

  .loadingContainer
    .spinner
      position absolute
      left calc(50% - 32px)
      top calc(50% - 32px)

  @media only screen and (max-width: 800px)
    .mobilityNearbyList
      width 100%
      right 0
</style>
